import React from 'react';

import { Page } from 'src/components';

const Legals = () => (
  <Page>
    <Page.Main className="container pt-24 space-y-12">
      <header>
        <h1 className="p-4 text-2xl font-bold text-center border-2 lg:text-3xl border-primary-light text-primary-light">
          Mentions Légales
          <br />
          Service Sharecare Awareness and Compliance Verification
        </h1>
        <p className="pt-6 text-center">
          Les présentes Conditions Générales d’Utilisation (ci-après les « CGU ») ont pour objet de
          définir les conditions d&apos;accès et d&apos;utilisation du Service proposé par Sharecare
          à ses clients dans le cadre de programmes de sensibilisation, de formation et/ou
          d’amélioration continue des compétences et activités concernées par un référentiel ou une
          norme donnée. Elles définissent également les obligations réciproques de chaque partie
          dans le cadre de l’utilisation du Service.
        </p>
      </header>
      <div>
        <span className="text-xl font-bold text-primary-light">Editeur</span>
        <div className="pt-4 space-y-4">
          <p>
            <strong>Dénomination et raison sociale :</strong> SHARECARE SASU
          </p>
          <p>
            <strong>Siret :</strong> n° 52773850400044
          </p>
          <p>
            <strong>Adresse :</strong> 105 avenue Raymond Poincaré 75116 Paris
          </p>
          <p>
            <strong>Directeur de la publication :</strong> Richard Dauphin
          </p>
          <p>
            <strong>Gestionnaire de site :</strong> Sharecare France
          </p>
          <p>
            <strong>Email :</strong> support.france@sharecare.com
          </p>
          <p>
            <strong>Hébergement :</strong>
            <br />
            Microsoft Ireland Opération Limited.
            <br />
            South county business park - One Microsoft Place
            <br />
            99204 Leopardstown – Dublin – Ireland
          </p>
          <p>
            <strong>Localisation :</strong> Europe
          </p>
          <p>
            <strong>Date de mise en ligne :</strong>
          </p>
          <p>
            <strong>Version :</strong> 1.0
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">
          Propriété intellectuelle et droits d&apos;auteurs
        </span>
        <div className="pt-4 space-y-4">
          <p>
            Le Service Sharecare Awareness and Compliance Verification (SACV) est une œuvre de
            l’esprit dont Sharecare est l’auteur au sens des articles du Code de la propriété
            intellectuelle.
          </p>
          <p>
            En ce sens, Sharecare et ses Fournisseurs sont et restent respectivement propriétaires
            de l’ensemble des droits, notamment de propriété intellectuelle, attachés à tous les
            éléments, matériels, documents et logiciels qu’ils mettent à disposition du Client dans
            le cadre de la Plateforme et du Service (ci-après les « Eléments »).
          </p>
          <p>
            Ainsi, les Conditions Générales d’Utilisation (CGU) ne confèrent au Client aucun droit
            de propriété sur les Eléments et leur mise à disposition temporaire pour les besoins du
            Service, et ne saurait s’analyser comme la cession d’un quelconque droit de propriété
            intellectuelle au bénéfice de l’Utilisateur, au sens du Code français de la propriété
            intellectuelle.
          </p>
          <p>
            Sharecare ou ses Fournisseurs concèdent seulement au Client, à titre non exclusif,
            personnel et non-transférable un droit d’utilisation temporaire sur les Eléments, limité
            aux seuls besoins de l’accès et utilisation de la Plateforme et du Service, qui lui est
            automatiquement retiré en cas de cessation d’utilisation du Service pour quelque raison
            que ce soit.
          </p>
          <p>
            En tout état de cause, Sharecare ne pourra être tenu responsable de l’utilisation par le
            Client de tout ou partie des Eléments appartenant à ses Fournisseurs, le Client étant et
            restant à tout moment le seul responsable des conséquences juridiques et financières
            attachées à l’utilisation qu’il fait de tout ou partie des Eléments.
          </p>
          <p>
            Sharecare ou ses Fournisseurs ne sont toutefois propriétaires d’aucune donnée publiée
            par le Client notamment via son Espace Privé, son Profil Utilisateur ou ses
            Commentaires.
          </p>
          <p>
            Le Client garantit que toutes les informations, données, fichiers, photographies etc.
            soit lui appartiennent, soit sont libres de droit, soit qu’il a reçu toutes les
            autorisations expresses nécessaires des titulaires préalablement à leur diffusion.
          </p>
          <p>
            Le Client s&apos;interdit en outre de diffuser des Commentaires incluant des adresses ou
            des liens hypertextes renvoyant vers des sites extérieurs qui soient contraires aux lois
            et règlements en vigueur, et qui portent atteinte aux droits des tiers ou qui soient
            contraires aux présentes Conditions Générales d’Utilisation.
          </p>
          <p>
            Aucun élément composant la Plateforme ne peut être copié, représenté reproduit, modifié,
            adapté, téléchargé, dénaturé, transmis ou distribué de quelque manière que ce soit, sous
            quelque support que ce soit, de façon partielle ou intégrale, sans l&apos;autorisation
            écrite et préalable de Sharecare. Toute reproduction autorisée devra comporter la
            mention « source : Sharecare France ».
          </p>
          <p>
            Le Client est informé que toute représentation totale ou partielle de la Plateforme par
            quelque procédé que ce soit, sans l&apos;autorisation expresse de Sharecare est ainsi
            strictement interdite et constituerait un acte de contrefaçon sanctionné par les
            articles L.335-2 et suivants du Code de la propriété intellectuelle que Sharecare se
            réserve le droit de poursuivre.
          </p>
          <p>
            Seule la copie à usage privé et interne de la Plateforme, pour les stricts besoins du
            Service, est autorisée. Le Client s’interdit ainsi d’utiliser les outils mis à
            disposition par Sharecare via la Plateforme dans un but autre que l’utilisation du
            Service et en dehors de la Plateforme.
          </p>
          <p>
            Le Client concède à Sharecare un droit d’utilisation gratuit des contenus qu&apos;ils
            publient, à l’exception des éléments relatifs aux Prestations ou tous éléments couverts
            par le secret professionnel, à des seules fins de fourniture du Service.
          </p>
        </div>
      </div>
      <div>
        <span className="text-xl font-bold text-primary-light">Crédits photos et vidéos</span>
        <div className="pt-4 space-y-4">
          <p>
            Les droits afférents aux illustrations et photographies contenues dans ce Service
            appartiennent, sauf mention contraire, à Sharecare France.
          </p>
          <p>
            Les auteurs des photographies sont, sauf mention particulière : Tous droits réservés
          </p>
        </div>
      </div>
    </Page.Main>
  </Page>
);

export default Legals;
